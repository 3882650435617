<template>
    <n-form-item label="Instrument" :rule="rule" :path="path">
        <n-select filterable v-model:value="model" :options="options" />
    </n-form-item>
</template>

<script>
import { NSelect, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import store from '../../store'

export default {
  name: 'InstrumentInput',
  components: {
    NSelect,
    NFormItem
  },
  props: {
    modelValue: Number,
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          required: true,
          type: 'number'
        },
        model: useVModel(props, "modelValue"),
        options: store.instrumentSelectOptions
    }
  }
}
</script>

<style>
</style>
