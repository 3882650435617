<template>
    <div style="text-align: center">
      <img
        src="/img/how-to.drawio.png"
        style="width: 100%; max-width: 700px"
        alt="Create a new patch. Export a patch to SD card with your Octapad. Load that patch file into this app. Edit whatever you want. Download the new patch file into SD card. Import the patch file into your Octapad."
      />
    </div>
</template>

<script>

export default {
  name: 'HowTo',
  components: {
  },
  props: {
  },
  setup () {
    return {
    }
  }
}
</script>

<style>
</style>
