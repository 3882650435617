<template>
    <n-form-item label="Name in SD card" :rule="rule" :path="path">
        <n-input v-model:value="model" :maxlength="12" />
    </n-form-item>
</template>

<script>
import { NInput, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import store from '../../store'

export default {
  name: 'BackupNameInput',
  components: {
    NInput,
    NFormItem
  },
  props: {
    modelValue: String,
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          validator (rule, value) {
            if (typeof value === "undefined" || value === null || value.length === 0) {
              return new Error("Must be filled")
            }
            if (Array.from(value).some(c => !store.values.supportedChars.includes(c))) {
              return new Error("Invalid characters")
            }
            return true
          },
        },
        model: useVModel(props, "modelValue"),
    }
  }
}
</script>

<style>
</style>
