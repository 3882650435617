import { createApp } from 'vue'
import App from './components/App.vue'

import 'vfonts/Lato.css'
import 'vfonts/FiraCode.css'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

createApp(App).use(VueLoading).mount('#app')
