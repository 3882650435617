import { computed } from 'vue'
import _ from 'lodash'

export default (formValue, filteredProps) => {
    return filteredProps.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.name]: computed(() => _.pickBy(formValue.value, (val, key) => key.startsWith(curr.filter))),
        }
    }, {
        mergeModel: (val) => formValue.value = {...formValue.value, ...val}
    })
}