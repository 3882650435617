<template>
  <div class="spd-padding-1">
    <n-grid x-gap="12" responsive="screen" cols="1 s:1 m:2 l:2 xl:2 2xl:2">
      <n-gi>
        <instrument-input :path="`${path}.instrument`" :modelValue="modelValue.instrument" @update:modelValue="updateModel($event, 'instrument')" />
        <pitch-input :path="`${path}.pitch`" :modelValue="modelValue.pitch" @update:modelValue="updateModel($event, 'pitch')" />
        <decay-input :path="`${path}.decay`" :modelValue="modelValue.decay" @update:modelValue="updateModel($event, 'decay')" />
        <pan-input :path="`${path}.pan`" :modelValue="modelValue.pan" @update:modelValue="updateModel($event, 'pan')" />
        <curve-input :path="`${path}.curve`" :modelValue="modelValue.curve" @update:modelValue="updateModel($event, 'curve')" />
        <level-input :path="`${path}.level`" :modelValue="modelValue.level" @update:modelValue="updateModel($event, 'level')" />
        <fx-send-input :path="`${path}.fxSend`" :modelValue="modelValue.fxSend" @update:modelValue="updateModel($event, 'fxSend')" />
      </n-gi>
      <n-gi>
        <midi-channel-input :path="`${path}.midiCh`" :modelValue="modelValue.midiCh" @update:modelValue="updateModel($event, 'midiCh')" />
        <midi-note-input :path="`${path}.midiNote`" :modelValue="modelValue.midiNote" @update:modelValue="updateModel($event, 'midiNote')" />
        <midi-gate-time-input :path="`${path}.midiGateTime`" :modelValue="modelValue.midiGateTime" @update:modelValue="updateModel($event, 'midiGateTime')" />
        <midi-program-change-input :path="`${path}.midiPgmCh`" :modelValue="modelValue.midiPgmCh" @update:modelValue="updateModel($event, 'midiPgmCh')" />
      </n-gi>
    </n-grid>
  </div>
</template>

<script>
import InstrumentInput from "./inputs/InstrumentInput.vue"
import PitchInput from "./inputs/PitchInput.vue"
import DecayInput from "./inputs/DecayInput.vue"
import PanInput from "./inputs/PanInput.vue"
import CurveInput from "./inputs/CurveInput.vue"
import LevelInput from "./inputs/LevelInput.vue"
import FxSendInput from "./inputs/FxSendInput.vue"
import MidiChannelInput from "./inputs/MidiChannelInput.vue"
import { NGrid, NGi } from 'naive-ui'

import MidiNoteInput from "./inputs/MidiNoteInput.vue"
import MidiProgramChangeInput from "./inputs/MidiProgramChangeInput.vue"
import MidiGateTimeInput from "./inputs/MidiGateTimeInput.vue"

import updateComposable from './composables/update'

export default {
  name: 'LayerEditor',
  components: {
    InstrumentInput,
    PitchInput,
    DecayInput,
    CurveInput,
    PanInput,
    LevelInput,
    FxSendInput,
    MidiChannelInput,
    MidiNoteInput,
    MidiProgramChangeInput,
    MidiGateTimeInput,
    NGrid,
    NGi
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    path: String,
  },
  setup (props) {
    return {
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
