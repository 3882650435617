const untilSuccess = async (fn) => {
    try {
        return await fn()
    } catch (e) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        return await untilSuccess(fn)
    }
}

export {
    untilSuccess
}