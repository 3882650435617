<template>
  <span class="spd-margin-left-1">
    <n-tooltip trigger="hover">
      <template #trigger>
        <n-button text :style="`font-size: ${fontSize}`" @click="copy">
          <n-icon><copy-icon /></n-icon>
        </n-button>
      </template>
      Copy
    </n-tooltip>
    <n-tooltip trigger="hover">
      <template #trigger>
        <n-button text class="spd-margin-left-1" :style="`font-size: ${fontSize}`" @click="paste" :disabled="!pasteEnabled">
          <n-icon><paste-icon /></n-icon>
        </n-button>
      </template>
      Paste
    </n-tooltip>
  </span>
</template>

<script>
import {NButton, NIcon, NTooltip} from 'naive-ui'
import { Copy as CopyIcon, Paste as PasteIcon } from '@vicons/fa'
import store from "../store"
import _ from "lodash"
import {computed} from "vue"
import updateComposable from './composables/update'
import { useMessage } from 'naive-ui'

export default {
  name: 'ClipboardButtons',
  components: {
    NButton,
    NIcon,
    CopyIcon,
    PasteIcon,
    NTooltip
  },
  props: {
    scope: String,
    modelValue: Object
  },
  setup(props) {
    const message = useMessage()
    const { replaceModel } = updateComposable(props, "modelValue")
    return {
      fontSize: "1em",
      copy () {
        store.clipboard = {
          scope: props.scope,
          data: _.cloneDeep(props.modelValue)
        }
        message.success(`Section "${props.scope}" copied to clipboard!`)
      },
      paste () {
        replaceModel(_.cloneDeep(store.clipboard.data))
        message.success(`Section "${store.clipboard.scope}" pasted from clipboard!`)
      },
      pasteEnabled: computed(() => store.clipboard !== null && store.clipboard.scope === props.scope),
    }
  }
}
</script>

<style>
</style>
