<template>
    <input v-show="false" type="file" ref="fileInput" @change="fileSelected" :key="inputKey" />
</template>

<script>
import {nextTick} from "vue"

export default {
  name: 'FileManager',
  components: {
  },
  data () {
      return {
          resolve: null,
          reject: null,
          inputKey: 0
      }
  },
  methods: {
      fileSelected () {
        if (this.$refs.fileInput.files.length < 1) return;
        const file = this.$refs.fileInput.files[0]
        const reader = new FileReader();
        reader.onload = (res) => {
          this.resolve({
              name: file.name,
              content: this.arrayBufferToHexString(res.target.result)
          })
        };
        reader.onerror = (err) => this.reject(err);
        reader.readAsArrayBuffer(file);
      },
      async loadFile () {
          this.inputKey = Date.now()
          await nextTick()
          return new Promise((resolve, reject) => {
              this.resolve = resolve
              this.reject = reject
              this.$refs.fileInput.click()
          })
      },
      downloadFile (content, name) {
        const blob = this.hexStringToBlob(content, "application/octet-stream")
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
      },
      arrayBufferToHexString(buffer) {
        return [...new Uint8Array(buffer)]
            .map(x => x.toString(16).padStart(2, '0'))
            .join('');
      },
      hexStringToBlob(hexStr, type){
        var buf = new ArrayBuffer(hexStr.length/2);
        var byteBuf = new Uint8Array(buf);
        for (let i=0; i<hexStr.length; i+=2) {
           byteBuf[i/2] = parseInt(hexStr.slice(i,i+2),16);
        }
        var blob = new Blob([byteBuf], {type});
        return blob;
      }
  }
}
</script>

<style>
</style>
