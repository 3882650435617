<template>
  <n-grid x-gap="12" y-gap="12" responsive="screen" cols="1 s:1 m:4 l:4 xl:4 2xl:4">
    <n-gi v-for="input of inputs" :key="input">
      <div class="spd-border">
        <div class="spd-border-bottom spd-heading">
          {{ input }}
          <clipboard-buttons scope="input" :modelValue="modelValue[input]" @update:modelValue="updateModel($event, input)" />
        </div>
        <div style="text-align: center">
          <PopupInputEditor :path="input" :modelValue="modelValue[input]" @update:modelValue="updateModel($event, input)" />
        </div>
      </div>
    </n-gi>
  </n-grid>
</template>

<script>
import PopupInputEditor from './PopupInputEditor'
import { NGrid, NGi } from 'naive-ui'
import store from '../store'
import { computed } from 'vue'
import updateComposable from './composables/update'
import ClipboardButtons from './ClipboardButtons.vue'

export default {
  name: 'InputsEditor',
  components: {
    PopupInputEditor,
    NGrid,
    NGi,
    ClipboardButtons
  },
  props: {
    filter: String,
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    return {
      inputs: computed(() => store.values.inputs.filter(i => i.startsWith(props.filter))),
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
