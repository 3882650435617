<template>
    <n-form-item label="Hi Gain [dB]" :rule="rule" :path="path">
        <n-input-number v-model:value="model" :min="-12" :max="12" />
    </n-form-item>
</template>

<script>
import { NInputNumber, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'

export default {
  name: 'EqHiGainInput',
  components: {
    NInputNumber,
    NFormItem
  },
  props: {
    modelValue: Number,
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          required: true,
          type: 'number'
        },
        model: useVModel(props, "modelValue"),
    }
  }
}
</script>

<style>
</style>
