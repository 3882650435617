<template>
  <div>
    <eq-on-off-input :path="`eqOnOff`" :modelValue="modelValue.eqOnOff" @update:modelValue="updateModel($event, 'eqOnOff')" />
    <eq-low-freq-input :path="`eqLowFreq`" :modelValue="modelValue.eqLowFreq" @update:modelValue="updateModel($event, 'eqLowFreq')" />
    <eq-low-gain-input :path="`eqLowGain`" :modelValue="modelValue.eqLowGain" @update:modelValue="updateModel($event, 'eqLowGain')" />
    <eq-low-mid-freq-input :path="`eqLowMidFreq`" :modelValue="modelValue.eqLowMidFreq" @update:modelValue="updateModel($event, 'eqLowMidFreq')" />
    <eq-low-mid-gain-input :path="`eqLowMidGain`" :modelValue="modelValue.eqLowMidGain" @update:modelValue="updateModel($event, 'eqLowMidGain')" />
    <eq-low-mid-q-input :path="`eqLowMidQ`" :modelValue="modelValue.eqLowMidQ" @update:modelValue="updateModel($event, 'eqLowMidQ')" />
    <eq-hi-mid-freq-input :path="`eqHiMidFreq`" :modelValue="modelValue.eqHiMidFreq" @update:modelValue="updateModel($event, 'eqHiMidFreq')" />
    <eq-hi-mid-gain-input :path="`eqHiMidGain`" :modelValue="modelValue.eqHiMidGain" @update:modelValue="updateModel($event, 'eqHiMidGain')" />
    <eq-hi-mid-q-input :path="`eqHiMidQ`" :modelValue="modelValue.eqHiMidQ" @update:modelValue="updateModel($event, 'eqHiMidQ')" />
    <eq-hi-freq-input :path="`eqHiFreq`" :modelValue="modelValue.eqHiFreq" @update:modelValue="updateModel($event, 'eqHiFreq')" />
    <eq-hi-gain-input :path="`eqHiGain`" :modelValue="modelValue.eqHiGain" @update:modelValue="updateModel($event, 'eqHiGain')" />
  </div>
</template>

<script>
import EqOnOffInput from "./inputs/EqOnOffInput.vue"
import EqLowFreqInput from "./inputs/EqLowFreqInput.vue"
import EqLowGainInput from "./inputs/EqLowGainInput.vue"
import EqLowMidFreqInput from "./inputs/EqLowMidFreqInput.vue"
import EqLowMidGainInput from "./inputs/EqLowMidGainInput.vue"
import EqLowMidQInput from "./inputs/EqLowMidQInput.vue"
import EqHiMidFreqInput from "./inputs/EqHiMidFreqInput.vue"
import EqHiMidGainInput from "./inputs/EqHiMidGainInput.vue"
import EqHiMidQInput from "./inputs/EqHiMidQInput.vue"
import EqHiFreqInput from "./inputs/EqHiFreqInput.vue"
import EqHiGainInput from "./inputs/EqHiGainInput.vue"
import updateComposable from './composables/update'

export default {
  name: 'EqualizerEditor',
  components: {
    EqOnOffInput,
    EqLowFreqInput,
    EqLowGainInput,
    EqLowMidFreqInput,
    EqLowMidGainInput,
    EqLowMidQInput,
    EqHiMidFreqInput,
    EqHiMidGainInput,
    EqHiMidQInput,
    EqHiFreqInput,
    EqHiGainInput,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    return {
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
