import {getCurrentInstance} from "vue"

export default (propsObj, propName) => {
    const instance = getCurrentInstance()
    const updateModel = (val, field) => {
        instance.emit("update:" + propName, {...propsObj[propName], [field]: val})
    }
    const replaceModel = (val) => {
        instance.emit("update:" + propName, val)
    }
    return {
        updateModel,
        replaceModel
    }
}