<template>
    <n-form-item label="Curve" :rule="rule" :path="path">
        <n-select v-model:value="model" :options="options" :render-label="renderLabel" :render-tag="renderTag" />
    </n-form-item>
</template>

<script>
import { NSelect, NFormItem, NAvatar } from 'naive-ui'
import { useVModel } from 'vue-composable'
import { computed, h } from 'vue'
import store from '../../store'

export default {
  name: 'CurveInput',
  components: {
    NSelect,
    NFormItem
  },
  props: {
    modelValue: String,
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          required: true,
        },
        model: useVModel(props, "modelValue"),
        options: computed(() => store.values.curve.map(e => ({
          label: e,
          value: e
        }))),
        renderLabel: (option) => {
          return h(
            'div',
            {
              style: {
                display: 'flex',
                alignItems: 'center',
                margin: '5px'
              }
            },
            [
              h(NAvatar, {
                src: "/img/curves/" + option.value + ".png",
                round: false,
                size: "large",
                style: {
                  marginRight: '12px'
                }
              }),
              option.label
            ]
          )
        },
        renderTag: ({ option }) => {
          return h(
            'div',
            {
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            },
            [
              h(NAvatar, {
                src: "/img/curves/" + option.value + ".png",
                round: false,
                size: "small",
                style: {
                  marginRight: '12px'
                }
              }),
              option.label
            ]
          )
        }
    }
  }
}
</script>

<style>
</style>
