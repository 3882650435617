<template>
  <div class="spd-border">
    <n-grid :cols="2">
      <n-gi v-for="(bank, i) in banks" :key="bank">
        <div :class="i > 0 ? 'spd-border-left' : ''">
          <div class="spd-border-bottom spd-heading">
            Bank {{ bank }}
            <clipboard-buttons scope="bank" :modelValue="modelValue[bank]" @update:modelValue="updateModel($event, bank)" />
          </div>
          <LayerEditor :path="`${path}.${bank}`" :modelValue="modelValue[bank]" @update:modelValue="updateModel($event, bank)" />
        </div>
      </n-gi>
    </n-grid>
  </div>
</template>

<script>
import LayerEditor from './LayerEditor.vue'
import { NGrid, NGi } from 'naive-ui'
import store from '../store'
import updateComposable from './composables/update'
import ClipboardButtons from './ClipboardButtons.vue'

export default {
  name: 'InputEditor',
  components: {
    LayerEditor,
    NGrid,
    NGi,
    ClipboardButtons,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    path: String
  },
  setup (props) {
    return {
      banks: store.values.banks,
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
