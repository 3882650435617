import { reactive, computed } from 'vue'
import _ from 'lodash'

const instrumentGroups = [
  { name: "Bass Drum (Kick)", from: 1, to: 50 },
  { name: "Snare Drum", from: 51, to: 136 },
  { name: "Tom-Tom", from: 137, to: 176 },
  { name: "Hi-Hat Cymbal", from: 177, to: 209 },
  { name: "Hi-Hat Cymbal (for Pedal Control)", from: 210, to: 226 },
  { name: "Crash/Ride Cymbal", from: 227, to: 262 },
  { name: "Latin Percussion", from: 263, to: 340 },
  { name: "Indian Percussion", from: 341, to: 373 },
  { name: "African/Middle Eastern/Australian/Other Percussion", from: 374, to: 410 },
  { name: "Japanese/Korean/Chinese/Southeast Asian Percussion", from: 411, to: 461 },
  { name: "Orchestral Percussion", from: 462, to: 485 },
  { name: "Melodic Percussion/Melodic Instrument", from: 486, to: 544 },
  { name: "Analog Percussion(CR-78, TR-808, etc.)", from: 545, to: 560 },
  { name: "Dance Sounds", from: 561, to: 603 },
  { name: "Artificial Sounds Effects", from: 604, to: 649 },
  { name: "Nature Sounds, Human Voice", from: 650, to: 680 },
  { name: "Ambience, Reversed Sounds", from: 681, to: 700 },
  { name: "New Indian Sounds", from: 701, to: 745 },
  { name: "Forces Phrase Loop Instruments to stop (Mute)", from: 746, to: 746 },
  { name: "No sound", from: 747, to: 747 },
]

const store = reactive({
    values: null,
    clipboard: null,
    instrumentSelectOptions: computed(() => {
        return instrumentGroups.reduce((acc, curr) => {
          let range
          if (curr.from === curr.to) {
            range = [curr.from] 
          } else {
            range = _.range(curr.from, curr.to+1)
          }
          return [
            ...acc,
            {
              type: "group",
              label: curr.name,
              key: curr.name,
              children: range.map(i => {
                const instrument = store.values.instruments.find(e => e.serialNumber === i)
                return {
                  label: instrument.nameOnLcd,
                  value: instrument.serialNumber
                }
              })
            }
          ]
        }, [])
      })
})

export default store