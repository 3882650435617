<template>
    <n-form-item label="Midi Note" :rule="rule" :path="path">
        <n-select v-model:value="model" :options="options" />
    </n-form-item>
</template>

<script>
import { NSelect, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import { computed } from 'vue'
import store from '../../store'

const generalMidiPercussionKeyMap = {
    35:		"Acoustic Bass Drum",
    36:		"Bass Drum 1",
    37:		"Side Stick",
    38:		"Acoustic Snare",
    39:		"Hand Clap",
    40:		"Electric Snare",
    41:		"Low Floor Tom",
    42:		"Closed Hi Hat",
    43:		"High Floor Tom",
    44:		"Pedal Hi-Hat",
    45:		"Low Tom",
    46:		"Open Hi-Hat",
    47:		"Low-Mid Tom",
    48:		"Hi Mid Tom",
    49:		"Crash Cymbal 1",
    50:		"High Tom",
    51:		"Ride Cymbal 1",
    52:		"Chinese Cymbal",
    53:		"Ride Bell",
    54:		"Tambourine",
    55:		"Splash Cymbal",
    56:		"Cowbell",
    57:		"Crash Cymbal 2",
    58:		"Vibraslap",
    59:		"Ride Cymbal 2",
    60:		"Hi Bongo",
    61:		"Low Bongo",
    62:		"Mute Hi Conga",
    63:		"Open Hi Conga",
    64:		"Low Conga",
    65:		"High Timbale",
    66:		"Low Timbale",
    67:		"High Agogo",
    68:		"Low Agogo",
    69:		"Cabasa",
    70:		"Maracas",
    71:		"Short Whistle",
    72:		"Long Whistle",
    73:		"Short Guiro",
    74:		"Long Guiro",
    75:		"Claves",
    76:		"Hi Wood Block",
    77:		"Low Wood Block",
    78:		"Mute Cuica",
    79:		"Open Cuica",
    80:		"Mute Triangle",
    81:		"Open Triangle",
}

export default {
  name: 'MidiNoteInput',
  components: {
    NSelect,
    NFormItem
  },
  props: {
    modelValue: [Number, String],
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          validator (rule, value) {
            if (typeof value === "undefined" || value === null) {
              return new Error("Must be filled")
            }
            return true
          },
        },
        model: useVModel(props, "modelValue"),
        options: computed(() => store.values.midiNotes.map(e => {
          let label = e
          if (generalMidiPercussionKeyMap[e]) {
            label = e + ": " + generalMidiPercussionKeyMap[e]
          }
          return {
            label,
            value: e
          }
        }))
    }
  }
}
</script>

<style>
</style>
