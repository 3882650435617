<template>
  <div class="spd-padding-1">
    <n-button @click="showModal = true">
        <template #icon>
          <n-icon>
            <edit-icon />
          </n-icon>
        </template>
        {{ description }}
    </n-button>
    <n-modal :style="{ width: '1000px' }" preset="card" v-model:show="showModal">
      <InputEditor :path="path" v-model="model" />
    </n-modal>
  </div>
</template>

<script>
import InputEditor from "./InputEditor.vue"
import { useVModel } from 'vue-composable'
import { ref, computed } from 'vue'
import { NModal, NButton, NIcon } from 'naive-ui'
import { Edit as EditIcon } from '@vicons/fa'
import store from '../store'

export default {
  name: 'PopupInputEditor',
  components: {
    InputEditor,
    NModal,
    NButton,
    EditIcon,
    NIcon,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    path: String
  },
  setup (props) {
    return {
      model: useVModel(props, "modelValue"),
      showModal: ref(false),
      description: computed(() => {
        if (typeof props.modelValue["A"] !== "undefined" &&
            typeof props.modelValue["B"] !== "undefined") {
          return "A: " + store.values.instruments.find(e => e.serialNumber === props.modelValue["A"].instrument).nameOnLcd + " | " +
             "B: " + store.values.instruments.find(e => e.serialNumber === props.modelValue["B"].instrument).nameOnLcd
        }
        return "Edit"
      })
    }
  }
}
</script>

<style>
</style>
