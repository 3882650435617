<template>
  <div>
    <comp-on-off-input :path="`compOnOff`" :modelValue="modelValue.compOnOff" @update:modelValue="updateModel($event, 'compOnOff')" />
    <comp-threshold-input :path="`compThreshold`" :modelValue="modelValue.compThreshold" @update:modelValue="updateModel($event, 'compThreshold')" />
    <comp-release-input :path="`compRelease`" :modelValue="modelValue.compRelease" @update:modelValue="updateModel($event, 'compRelease')" />
    <comp-attack-input :path="`compAttack`" :modelValue="modelValue.compAttack" @update:modelValue="updateModel($event, 'compAttack')" />
    <comp-ratio-input :path="`compRatio`" :modelValue="modelValue.compRatio" @update:modelValue="updateModel($event, 'compRatio')" />
    <comp-output-gain-input :path="`compOutputGain`" :modelValue="modelValue.compOutputGain" @update:modelValue="updateModel($event, 'compOutputGain')" />
  </div>
</template>

<script>
import CompOnOffInput from "./inputs/CompOnOffInput.vue"
import CompThresholdInput from "./inputs/CompThresholdInput.vue"
import CompReleaseInput from "./inputs/CompReleaseInput.vue"
import CompAttackInput from "./inputs/CompAttackInput.vue"
import CompRatioInput from "./inputs/CompRatioInput.vue"
import CompOutputGainInput from "./inputs/CompOutputGainInput.vue"
import updateComposable from './composables/update'

export default {
  name: 'CompressorEditor',
  components: {
    CompOnOffInput,
    CompThresholdInput,
    CompReleaseInput,
    CompAttackInput,
    CompRatioInput,
    CompOutputGainInput,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    return {
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
