<template>
    <n-form-item :label="label" :rule="rule" :path="path">
        <n-input-number v-model:value="model" :min="1" :max="32" />
    </n-form-item>
</template>

<script>
import { NInputNumber, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import store from '../../store'
import { computed } from 'vue'

export default {
  name: 'FxTimeInput',
  components: {
    NInputNumber,
    NFormItem
  },
  props: {
    modelValue: Number,
    path: String,
    fxType: Number
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          required: true,
          type: 'number'
        },
        model: useVModel(props, "modelValue"),
        label: computed(() => {
          if (typeof props.fxType == "number") {
            return store.values.effects.find(fx => fx.number == props.fxType).parameter
          }
          return "Time"
        })
    }
  }
}
</script>

<style>
</style>
