<template>
    <n-form-item label="Midi Ch" :rule="rule" :path="path">
        <n-select v-model:value="model" :options="options" />
    </n-form-item>
</template>

<script>
import { NSelect, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import { computed } from 'vue'
import store from '../../store'

export default {
  name: 'MidiChannelInput',
  components: {
    NSelect,
    NFormItem
  },
  props: {
    modelValue: [Number, String],
    path: String
  },
  setup(props) {
    return {
        rule: {
          trigger: ['input', 'blur'],
          validator (rule, value) {
            if (typeof value === "undefined" || value === null) {
              return new Error("Must be filled")
            }
            return true
          },
        },
        model: useVModel(props, "modelValue"),
        options: computed(() => store.values.midiChannels.map(e => ({
          label: e,
          value: e
        })))
    }
  }
}
</script>

<style>
</style>
