<template>
  <div>
    <div class="spd-margin-bottom-1">
      <n-space align="center" justify="space-between">
        <span>
            <n-button @click="handleAddFromScratch">
                <template #icon>
                  <n-icon>
                    <sticky-note-icon />
                  </n-icon>
                </template>
                New
            </n-button>
            <n-button @click="handleAddFromFile" class="spd-margin-left-1">
                <template #icon>
                  <n-icon>
                    <folder-open-icon />
                  </n-icon>
                </template>
                Load
            </n-button>
        </span>
        <span>Developed by Matías Beretta. Send feedback to <a href="mailto:msberetta92@gmail.com">msberetta92@gmail.com</a>.</span>
      </n-space>
    </div>
    <div class="spd-margin-bottom-1">
      <n-tabs
          v-model:value="value"
          type="card"
          :closable="true"
          tab-style="min-width: 80px;"
          @close="handleClose"
      >
          <n-tab :closable="false" name="how_to">
            How to?
          </n-tab>
          <n-tab v-for="panel in panels" :key="`tab-${panel.name}`" :name="panel.name">
            {{ panel.title }}
          </n-tab>
      </n-tabs>
    </div>
    <div v-for="panel in panels" :key="`editor-${panel.name}`">
      <patch-editor v-show="value == panel.name" :data="panel.file" />
    </div>
    <div v-show="value == 'how_to'">
      <how-to/>
    </div>
    <file-manager ref="fileManagerRef" />
  </div>
</template>

<script>
import { NTabs, NTab, NButton, NIcon, NSpace } from 'naive-ui'
import { ref } from 'vue'
import PatchEditor from './PatchEditor.vue'
import FileManager from './FileManager.vue'
import HowTo from "./HowTo.vue"
import { FolderOpen as FolderOpenIcon, StickyNote as StickyNoteIcon } from '@vicons/fa'

export default {
  name: 'MultiplePatchEditor',
  components: {
    NTabs,
    NTab,
    PatchEditor,
    FileManager,
    NButton,
    NIcon,
    FolderOpenIcon,
    StickyNoteIcon,
    NSpace,
    HowTo
  },
  setup () {
    const valueRef = ref("how_to")
    const panelsRef = ref([])
    const fileManagerRef = ref(null)
    return {
      value: valueRef,
      panels: panelsRef,
      async handleAddFromFile () {
        const file = await fileManagerRef.value.loadFile()
        const newValue = {
            name: Date.now(),
            title: file.name,
            file,
        }
        panelsRef.value.push(newValue)
        valueRef.value = newValue.name
      },
      async handleAddFromScratch () {
        const file = {
            name: "SPD20X01.BIN",
            content: "4241434b555020202020202000004f2a0005e20060093f1e03049c00540b7f1e03049d00540bbf2603049d0098087b1e0201010098087f2408073f0060094e1e0003b000fc084e1f0003d100fc087f1e0202070098087f1e0003110060097f2208043e00c4097f1f0008ea0260097f1f0008ea0260097f1f0008ea0260097f1e09082c0160097d280002470160097f1f0008ea0260093422000f3f00c409741e000f3f00c409b41e000f3f00c4097b250c010c0098087f240c07340060094f1e0b03b100fc084f1f0b03d200fc087c230c02000098087f1e00031900fc087f260c043f00c4097d1e0005400060094f1f0003d100fc084f1f0003d200fc087f1e0d082c01c4097f1f0008ea02600909000e003100090080003000090080002d00090080002900090080002400090080002600090080001600090080002e0009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800009008000800001000b0c010c050c00050c00080c0c06036414380c46756e6b2020202020202020000090"
        }
        const newValue = {
            name: Date.now(),
            title: file.name,
            file,
        }
        panelsRef.value.push(newValue)
        valueRef.value = newValue.name
      },
      handleClose (name) {
        const { value: panels } = panelsRef
        panels.splice(panels.findIndex((panel) => panel.name === name), 1)
        if (name === valueRef.value) {
          valueRef.value = "how_to"
        }
      },
      fileManagerRef
    }
  }
}
</script>

<style>
</style>
