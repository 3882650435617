<template>
  <div>
    <fx-on-off-input :path="`fxOnOff`" :modelValue="modelValue.fxOnOff" @update:modelValue="updateModel($event, 'fxOnOff')" />
    <fx-type-input :path="`fxType`" :modelValue="modelValue.fxType" @update:modelValue="updateModel($event, 'fxType')" />
    <fx-time-input :fxType="modelValue.fxType" :path="`fxTime`" :modelValue="modelValue.fxTime" @update:modelValue="updateModel($event, 'fxTime')" />
    <fx-level-input :path="`fxLevel`" :modelValue="modelValue.fxLevel" @update:modelValue="updateModel($event, 'fxLevel')" />
  </div>
</template>

<script>
import FxOnOffInput from './inputs/FxOnOffInput.vue'
import FxTypeInput from "./inputs/FxTypeInput.vue"
import FxTimeInput from "./inputs/FxTimeInput.vue"
import FxLevelInput from "./inputs/FxLevelInput.vue"
import updateComposable from './composables/update'

export default {
  name: 'EffectsEditor',
  components: {
    FxTypeInput,
    FxTimeInput,
    FxLevelInput,
    FxOnOffInput
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    return {
      ...updateComposable(props, "modelValue")
    }
  }
}
</script>

<style>
</style>
