<template>
  <n-config-provider :theme-overrides="{ common: { fontWeightStrong: '600' } }">
    <n-message-provider>
      <div class="spd-padding-1">
        <h1 style="font-size: 1.2em">Create and edit Roland SPD20X patches with this free and convenient editor!</h1>
        <multiple-patch-editor />
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import { getValues } from '../api'
import { NConfigProvider, NMessageProvider } from 'naive-ui'
import MultiplePatchEditor from './MultiplePatchEditor.vue'
import store from '../store'
import { onMounted } from '@vue/runtime-core'
import { untilSuccess } from '../helpers'
import { inject } from 'vue'

export default {
  name: 'App',
  components: {
    NConfigProvider,
    MultiplePatchEditor,
    NMessageProvider
  },
  setup () {
    const $loading = inject('$loading')
    onMounted(async () => {
      const loader = $loading.show();
      try {
        store.values = await untilSuccess(() => getValues())
      } finally {
        loader.hide()
      }
    })
    return { }
  }
}
</script>

<style>
  .spd-padding-1 {
    padding: 5px
  }
  .spd-margin-bottom-1 {
    margin-bottom: 5px
  }
  .spd-margin-left-1 {
    margin-left: 5px
  }
  .spd-border {
    border-style: solid;
    border-width: 1px;
    border-color: #d3d3d3
  }
  .spd-border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #d3d3d3
  }
  .spd-border-left {
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #d3d3d3
  }
  .spd-heading {
    text-align: center;
    font-weight: bold;
    background-color: #E0E0E0
  }
</style>
