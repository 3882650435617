import axios from 'axios'

const callApi = async (path, method, data) => {
    const res = await axios({
        method,
        url: path,
        data
    })
    return res.data
}

const loadPatch = (bytecode) => callApi("/patch/load", "POST", { bytecode })
const savePatch = (bytecode, updates) => callApi("/patch/save", "POST", { bytecode, updates })
const getValues = () => callApi("/values", "GET")

export {
    loadPatch,
    savePatch,
    getValues,
}
