<template>
    <n-form-item label="Pitch" :rule="rule" :path="path">
        <n-input-number v-model:value="model" :min="-24" :max="24">
          <template #suffix>
            {{ noteName }}
          </template>
        </n-input-number>
    </n-form-item>
</template>

<script>
import { NInputNumber, NFormItem } from 'naive-ui'
import { useVModel } from 'vue-composable'
import { computed } from 'vue'

const notes = [
  "C",
  "C# | Db",
  "D",
  "D# | Eb",
  "E",
  "F",
  "F# | Gb",
  "G",
  "G# | Ab",
  "A",
  "A# | Bb",
  "B"
]

export default {
  name: 'PitchInput',
  components: {
    NInputNumber,
    NFormItem
  },
  props: {
    modelValue: Number,
    path: String
  },
  setup(props) {
    const model = useVModel(props, "modelValue")
    return {
        rule: {
          trigger: ['input', 'blur'],
          required: true,
          type: 'number'
        },
        model,
        noteName: computed(() => {
          return notes[(model.value + 24) % notes.length]
        })
    }
  }
}
</script>

<style>
</style>
