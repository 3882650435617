<template>
  <n-form
    ref="formRef"
    :model="formValue"
  >
    <n-space align="center" justify="space-between">
      <n-space>
        <NameInput path="name" v-model="formValue.name" />
        <BackupNameInput path="backupName" v-model="formValue.backupName" />
        <BackupIndexInput path="backupIndex" v-model="formValue.backupIndex" />
        <ActiveBankInput path="activeBank" v-model="formValue.activeBank" />
        <LayerOnOffInput path="layerOnOff" v-model="formValue.layerOnOff" />
      </n-space>
      <n-button @click="save">
        <template #icon>
          <n-icon>
            <download-icon />
          </n-icon>
        </template>
        Download
      </n-button>
    </n-space>
    <h2>Pads<clipboard-buttons scope="pads" :modelValue="padValues" @update:modelValue="mergeModel"/></h2>
    <InputsEditor filter="PAD_" :modelValue="padValues" @update:modelValue="mergeModel" />
    <h2>Triggers<clipboard-buttons scope="triggers" :modelValue="triggerValues" @update:modelValue="mergeModel"/></h2>
    <InputsEditor filter="TRIGGER_" :modelValue="triggerValues" @update:modelValue="mergeModel" />
    <n-grid x-gap="12" y-gap="12" cols="1 s:1 m:3 l:3 xl:3 2xl:3" responsive="screen">
      <n-gi>
        <h2>Effects<clipboard-buttons scope="effects" :modelValue="fxValues" @update:modelValue="mergeModel"/></h2>
        <EffectsEditor :modelValue="fxValues" @update:modelValue="mergeModel" />
      </n-gi>
      <n-gi>
        <h2>Equalizer<clipboard-buttons scope="equalizer" :modelValue="eqValues" @update:modelValue="mergeModel"/></h2>
        <EqualizerEditor :modelValue="eqValues" @update:modelValue="mergeModel" />
      </n-gi>
      <n-gi>
        <h2>Compressor<clipboard-buttons scope="compressor" :modelValue="compValues" @update:modelValue="mergeModel"/></h2>
        <CompressorEditor :modelValue="compValues" @update:modelValue="mergeModel" />
      </n-gi>
    </n-grid>
    <file-manager ref="fileManagerRef" />
  </n-form>
</template>

<script>
import { ref, onMounted, inject } from "vue"
import { loadPatch, savePatch } from '../api'
import { NButton, NIcon, NSpace, NForm, NGrid, NGi, useMessage } from 'naive-ui'
import FileManager from './FileManager.vue'
import { Download as DownloadIcon } from '@vicons/fa'
import InputsEditor from './InputsEditor'
import EffectsEditor from './EffectsEditor'
import EqualizerEditor from './EqualizerEditor'
import CompressorEditor from './CompressorEditor'
import NameInput from './inputs/NameInput'
import LayerOnOffInput from './inputs/LayerOnOffInput'
import ActiveBankInput from './inputs/ActiveBankInput'
import BackupNameInput from './inputs/BackupNameInput'
import BackupIndexInput from './inputs/BackupIndexInput'
import mergeComposable from './composables/merge'
import ClipboardButtons from './ClipboardButtons.vue'
import { untilSuccess } from '../helpers'

export default {
  name: 'PatchEditor',
  components: {
    NButton,
    FileManager,
    NIcon,
    DownloadIcon,
    NSpace,
    InputsEditor,
    EffectsEditor,
    EqualizerEditor,
    CompressorEditor,
    NameInput,
    NForm,
    ClipboardButtons,
    BackupNameInput,
    BackupIndexInput,
    NGrid,
    NGi,
    LayerOnOffInput,
    ActiveBankInput
  },
  props: {
    data: Object
  },
  setup (props) {
    const fileManagerRef = ref(null)
    const formValue = ref({})
    const formRef = ref(null)
    const message = useMessage()
    const $loading = inject("$loading")

    onMounted(async () => {
      const loader = $loading.show();
      try {
        formValue.value = await untilSuccess(() => loadPatch(props.data.content))
      } finally {
        loader.hide()
      }
    })

    const save = (e) => {
      e.preventDefault()
      formRef.value.validate(async (errors) => {
        if (errors) return
        const loader = $loading.show();
        try {
          const result = await savePatch(props.data.content, formValue.value)
          fileManagerRef.value.downloadFile(result.bytecode, `SPD20X${formValue.value.backupIndex.toString().padStart(2, '0')}.BIN`)
        } catch (e) {
          message.error("Error while talking to server. Try again...")
        } finally {
          loader.hide()
        }
      })
    }

    return {
      save,
      fileManagerRef,
      formValue,
      formRef,
      ...mergeComposable(formValue, [
        { name: "padValues", filter: "PAD_" },
        { name: "triggerValues", filter: "TRIGGER_" },
        { name: "fxValues", filter: "fx" },
        { name: "compValues", filter: "comp" },
        { name: "eqValues", filter: "eq" },
      ])
    }
  }
}
</script>

<style>
</style>
